<template>
    <component :is="component" :data="data" :widgetIndex="widgetIndex" :widgetGroupLength="widgetGroupLength" v-if="component"></component>
</template>

<script>
    export default {
        name: 'PostWidget',
        props: {
            data: Object,
            widgetIndex: Number,
            widgetGroupLength: Number
        },

        data() {
            return {
                component: null
            }
        },

        mounted() {
            this.loader()
                .then(() => {
                    this.component = () => this.loader()
                })
                .catch(e => {
                    /*eslint no-console: ["error", { allow: ["error"] }] */
                    console.error(e)
                })

        },

        computed: {
            loader() {
                const upper = str => str.replace(/^\w/, c => c.toUpperCase());
                let component = upper(this.data.acf_fc_layout.replace('widget_', ''));
                return () => import( /* webpackChunkName: "post_widget" */ `@/components/post/builder/${ component }`)
            }
        }
    }
</script>
