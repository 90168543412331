<template>
    <header class="c-article__header c-intro o-container o-container--full o-container--no-pad">
        <div v-if="data.img" class="c-intro__img" v-lazy:background-image="data.img.sizes.full.src[0]" :style="{ backgroundPosition: data.imgPos ? data.imgPos.replace(',', ' ') : null }">
<!--            <div class="c-intro__placeholder" :style="{ backgroundImage: `url(${ data.img.sizes.placeholder.src[0] })` }"></div>-->
        </div>

        <div class="o-container o-container--intro">
            <div class="c-intro__content">
                <span class="c-txt c-txt--upper c-txt--bold u-color--primary">{{ data.sub }}</span>
                <h1 class="c-intro__title">{{ data.title }}</h1>
                <time class="c-intro__date c-meta" :datetime="data.date">{{ data.date_formatted }}</time>
                <p v-if="data.excerpt" class="c-intro__text c-txt c-txt--lead">{{ data.excerpt }}</p>
            </div>
        </div>

        <post-author v-if="data.author" :author="data.author"></post-author>
    </header>
</template>

<script>
    export default {
        name: 'intro',
        props: {
            data: Object
        },

        components: {
            'post-author' : () => import( /* webpackChunkName: "Post" */ '@/components/post/Author' )
        }
    }
</script>