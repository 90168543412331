<template>
    <div class="o-container">
        <div class="c-vcard">
            <div class="c-vcard__img"
                 :style="{ backgroundImage : `url(${data.img_field ? data.img_field.url : '' })` }"></div>
            <span class="fn c-vcard__title">{{ data.name }}</span>
            <span class="c-vard__workfield">{{ data.field }}</span>
            <div class="c-vcard__contact">
                <span class="c-vcard__contact-field">
                    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17" v-bind:svg-inline="''" v-bind:class="'c-icon c-icon--inline'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.982 6.846l-.003-.002a350.003 350.003 0 01-2.096-1.342c-.414-.267-.704-.455-.864-.553a6.133 6.133 0 01-1.446-1.337l-.006-.007-.006-.008C1.11 3.053 1 2.674 1 2.416c0-.481.138-.824.336-1.046.205-.226.483-.37.976-.37h21.32c.362 0 .665.11.968.369.232.218.358.482.4.811-.01.469-.154.898-.504 1.374a5.836 5.836 0 01-1.508 1.39l-.009.006-3.19 2.041a607.398 607.398 0 00-3.586 2.302l-.01.007-.01.007-.287.192-.322.216-.024.015-.022.017a4.92 4.92 0 01-.465.31 10.51 10.51 0 00-.247.156l-.258.152c-.142.083-.281.166-.445.257a4.838 4.838 0 01-.661.288c-.266.073-.375.09-.452.09a1.66 1.66 0 01-.44-.086l-.013-.004-.014-.004a2.991 2.991 0 01-.628-.275l-.018-.01-.017-.008a6.499 6.499 0 01-.66-.383l-.026-.017-.026-.015a7.39 7.39 0 01-.447-.291c-.083-.056-.176-.12-.285-.192a79.21 79.21 0 01-.273-.184c-.163-.11-.3-.203-.373-.249-.507-.32-1.118-.714-1.853-1.187L5.981 6.846z"/><path d="M1 2v14h24V2"/></svg>
                    <a :href="`mailto:${ data.email }`" title="Ansprechpartner/in per Email kontaktieren">{{ data.email }}</a>
                </span>
                <span class="c-vcard__contact-field">
                    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:class="'c-icon c-icon--inline'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M21.302 16.409c-3.1-2.519-4.208-1.408-5.537-.075l-.221.223c-.37.37-1.33-.148-1.846-.445-.812-.518-1.92-1.48-3.1-2.666-3.766-3.777-3.323-4.74-3.175-4.962l.221-.222c1.33-1.26 2.437-2.444-.074-5.555-.812-1.11-1.624-1.703-2.51-1.703C3.805.93 2.846 1.967 2.034 2.78c-.074.148-.222.296-.37.445-.885.888-.885 2.888 0 5.332 1.034 2.666 2.953 5.555 5.611 8.147 2.584 2.592 5.463 4.592 8.121 5.628 1.181.445 2.289.667 3.248.667.886 0 1.625-.222 2.067-.667.148-.148.296-.296.443-.37.886-.815 1.846-1.777 1.846-3.036-.074-.89-.59-1.704-1.698-2.518z"/></svg>
                    <a :href="`tel:${ data.phone }`" title="Ansprechpartner/in anrufen">{{ data.phone }}</a>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ContactPerson',
        props: {
            data: Object
        }
    }
</script>

<style scoped lang="scss" src="@/styles/components/_vcard.scss"></style>