<template>
    <main class="o-main o-main--post" id="main">
        <div v-if="loaded && post.post_status === 'draft'" class="c-notice c-notice--info">
            <div class="o-container">ARTIKELVORSCHAU</div>
        </div>
        <article v-if="loaded && post" class="c-article">
            <post-intro :data="introData"/>
            <div ref="stickyContainer" id="stickyContainer">
                <action-bar :bardata="post.post_share" :postID="post.id" :vertical="true"/>

                <div class="c-widget c-widget--post" v-for="(item, i) in postBuilderGroupedVideos" :key="i">
                    <div class="c-widget-group o-container" v-if="Array.isArray(item)">
                        <post-widget v-for="(widget, i) in item" :data="widget"
                                     :key="`${post.id}_${i}`" :widgetIndex="i" :widgetGroupLength="item.length" />
                    </div>
                    <post-widget v-else :data="item" />
                </div>
                <post-contact-person v-if="postInfo.contactPerson" :data="personData"/>
            </div>
            <post-related v-if="post_status !== 'draft'" :postID="post.id" :tags="postTags" :related="relatedPosts"
                          :bardata="post.post_share"/>
        </article>
        <Loader v-else/>
    </main>
</template>

<script>
    import api from '@/api'
    import {mapGetters, mapActions} from 'vuex'

    import actionBar from '@/components/partials/ActionBar'
    import Loader from '@/components/partials/Loader.vue';
    import postWidget from '@/components/post/Widget.vue'
    import postIntro from '@/components/post/Intro.vue'
    import postContactPerson from '@/components/post/ContactPerson.vue'

    export default {
        name: 'post',

        watch: {
            'post': function () {
                this.post_status = this.post.post_status

                if (this.post_status !== 'draft') this.updatePostHistory()
            }
        },

        data() {
            return {
                post: null,
                post_status: '',
                loaded: false
            }
        },

        async beforeMount() {
            if (this.$route.params.id) {
                await api.getPostPreview(Number(this.$route.params.id), post => {
                    this.loaded = true
                    this.post = post
                    document.title = `${this.post.post_title} | Rheiner`
                } ,false)
            } else {
                await api.getPost(this.$route.params.postSlug, this.userOid, post => {
                    this.loaded = true
                    this.post = post
                    document.title = `${this.post.post_title} | Rheiner`

                    if (!this.postInUserHistory) this.updateUserHistory({'oid': this.userOid, 'id': this.post.id})
                }, false)
            }
        },

        mounted() {
            if (this.currentPost) document.title = `${this.currentPost.post_title} | Rheiner`
        },

        beforeDestroy() {
            if (this.$mq !== 'small' || this.$mq !== 'medium') window.removeEventListener('scroll', this.handleScroll)
            this.$store.commit('POST_LOADED', false)
        },

        methods: {
            ...mapActions(['updateUserHistory', 'getPostPreview', 'getPost']),

            updatePostHistory() {
                const {
                    id,
                    slug: post_name,
                    post_date_default,
                    post_date_formatted,
                    post_title,
                    post_tagline,
                    featured_image
                } = this.post

                const post = {
                    id,
                    post_tagline,
                    post_name,
                    post_title,
                    featured_image,
                    post_date_default,
                    post_date_formatted
                };

                const postHistory = JSON.parse(localStorage.getItem('posthistory')) || [];
                const isInArr = e => e.id === this.post.id;

                if (postHistory.find(isInArr)) {
                    const postInHistory = postHistory.find(isInArr);

                    if (postHistory.indexOf(postInHistory) > 0) { // Update "Post history" for posts which are already in that array
                        postHistory.splice(postHistory.indexOf(postInHistory), 1);
                        postHistory.unshift(postInHistory);
                    }
                } else {
                    postHistory.unshift(post);
                }

                localStorage.setItem('posthistory', JSON.stringify(postHistory))
            },
        },

        computed: {
            ...mapGetters({
                isLoading: 'isLoading',
								userOid: 'userOid',
								userUid: 'userUid',
                userHistory: 'userHistory',
            }),

            introData() {
                const data = this.post;

                const {
                    post_title: title,
                    post_tagline: sub,
                    featured_image: img,
                    featured_image_position: imgPos,
                    date,
                    post_date_formatted: date_formatted,
                    post_excerpt: excerpt,
                    author_data: author

                } = data;

                return {title, sub, img, imgPos, date, date_formatted, excerpt, author};
            },

            personData() {
                const data = this.post.post_contactperson[0];

                const {
                    post_title: name,
                    contact_email: email,
                    contact_phone: phone,
                    contact_specialfield: field,
                    featured_image: img,
                    featured_image_test: img_field

                } = data;

                return {name, email, phone, field, img, img_field};
            },

            postTags() {
                const data = this.post;
                const arr = [];
                const concatArr = arr.concat(data.post_tag_content, data.post_tag_main, data.post_tag_meta)
                return concatArr.map(Obj => {
                    const {
                        post_title: title,
                        post_name: slug,
                        ID: id
                    } = Obj;

                    return {title, slug, id};
                });
            },

            relatedPosts() {
                const related = this.post.related_posts;
                return related.map(Obj => {
                    const {
                        ID,
                        post_title,
                        featured_image,
                        post_name,
                        post_tagline
                    } = Obj;

                    return {ID, post_title, post_tagline, featured_image, post_name};
                })
            },

            postInfo() {
                const d = this.post;
                const {
                    post_builder: builder = null,
                    post_contactperson: contactPerson = null
                } = d;

                return {builder, contactPerson}
            },

            postInUserHistory() {
                if (!this.userHistory.data) return false
                const postArrByID = this.userHistory.data.map(post => post.post.ID)
                return postArrByID.includes(this.post.id)
            },

            postBuilderGroupedVideos () {
                if (!this.post.post_builder) return false

                const result = this.post.post_builder.reduce((accum, curr, index, array) => {
                    if (curr.acf_fc_layout === 'widget_video') {
                        if (Array.isArray(accum.result[accum.lastIndex])) {
                            accum.result[accum.lastIndex].push(curr)
                        }
                        else {
                            if ((index + 1 < array.length && array[index + 1].acf_fc_layout !== 'widget_video') || index === array.length - 1) {
                                accum.result.push(curr)
                            }
                            else {
                                accum.result.push([])
                                accum.result[accum.result.length - 1].push(curr)
                            }
                            accum.lastIndex = accum.result.length - 1
                        }
                    }
                    else {
                        accum.result.push(curr)
                        accum.lastIndex = accum.result.length - 1
                    }
                    return accum
                }, { result: [], lastIndex: -1 })

                return result.result
            }

        },

        components: {
            actionBar,
            Loader,
            postWidget,
            postIntro,
            postContactPerson,
            'post-related': () => import( /* webpackChunkName: "Post" */ '@/components/post/Related' ),
        }
    };
</script>

<style lang="scss" src="@/styles/components/_intro.scss"></style>
<style lang="scss" src="@/styles/components/_author.scss"></style>
<style lang="scss" src="@/styles/components/_notice.scss"></style>
