<template>
    <div class="c-interaction-bar" :class="{ 'c-interaction-bar--vertical' : vertical }" :style="barStyle">
        <action-link
                class="c-link c-link--is-icon"
                :class="`c-link--is-icon-${el.icon}`"
                v-for="(el, eli) in extendedBardata"
                :data="el"
                :id="postID"
                :key="`action-link-${eli}`"/>
    </div>
</template>

<script>
    import ActionLink from '@/components/partials/ActionLink'

    export default {
        name: 'InteractionBar',
        props: {
            bardata: Array,
            cssClass: String,
            postID: Number,
            vertical: Boolean
        },

        data () {
          return {
              barStyle: {}
          }
        },

        mounted() {
            this.posBar()

            window.addEventListener('resize', this.posBar)
        },

        destroyed() {
            window.removeEventListener('resize', this.posBar)
        },

        methods: {
            posBar() {
                if (!this.vertical) return false;
                const introEl = document.querySelector('.c-intro')
                const browserWidth = Math.max(
                    document.body.scrollWidth,
                    document.documentElement.scrollWidth,
                    document.body.offsetWidth,
                    document.documentElement.offsetWidth,
                    document.documentElement.clientWidth
                )

                this.barStyle = {
                    marginRight: `${browserWidth - introEl.getBoundingClientRect().right + 64}px`
                }
            }
        },

        computed: {
            extendedBardata () {
                const interactiveButtons = [
                    {
                        action: 'button',
                        icon: 'bookmark',
                        routeName: 'bookmark'
                    },
                    {
                        action: 'button',
                        icon: 'heart',
                        routeName: 'like'
                    }
                ];

                return interactiveButtons.concat(this.bardata)
            },
        },

        components: {
            ActionLink
        }
    }
</script>

<style scoped lang="scss" src="@/styles/components/_interaction-bar.scss"></style>
<style scoped lang="scss" src="@/styles/components/_link.scss"></style>